<template>
  <div>
    <el-form label-position="right">
      <TitleAndDescription :field="field" />
      <el-row type="flex" :gutter="50">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <br />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Integrations">
            <el-select
              v-model="field.integration_settings.selectedIntegrationField"
              no-data-text="No Integrations available"
              filterable
              style="width: 100%"
              @change="handleSelect"
            >
              <el-option
                v-for="integration in filteredIntegrations"
                :value="integration.key"
                :key="integration.key"
                :label="integration.label"
              >
                <span style="float: left">{{ integration.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Integration Variable">
            <el-select
              v-model="
                field.integration_settings.selectedIntegrationFieldVariable
              "
              placeholder="Select a Variable"
              no-data-text="No Variables available"
              filterable
              style="width: 100%"
              @change="updateSelectedVariable"
            >
              <el-option
                v-for="(variable, index) in integrationVariables"
                :key="index"
                :label="variable.label"
                :value="variable.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <div class="form-group">
            <is-field-required :field="field" class="field-required" />
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { postAPICall } from "../../../helpers/httpHelper";

export default {
  props: ["field", "fieldsData", "index"],
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    IsFieldRequired: () => import("./IsFieldRequired"),
  },
  computed: {
    filteredIntegrations() {
      return this.fieldsData.filter(
        (field) =>
          field.input_type && field.input_type.toUpperCase() === "INTEGRATION"
      );
    },
  },
  async mounted() {
    await this.fetchAllPredefinedIntegrations();
    this.handleSelect();
  },
  data() {
    return {
      selectedIntegrationField: null,
      selectedIntegrationFieldVariable: null,
      selectedIntegrationFieldVariableType: null,
      integrationVariables: [],
    };
  },
  methods: {
    updateSelectedVariable(value) {
      const variableChosen = this.integrationVariables.find(
        (variable) => variable.key === value
      );
      if (variableChosen) {
        this.field.integration_settings.selectedIntegrationFieldVariableType =
          variableChosen.type;
      }
    },
    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
    handleSelect() {
      try {
        let macthedIntegrationField = this.fieldsData.find(
          (field) =>
            field.key ==
            this.field.integration_settings.selectedIntegrationField
        );
        let macthedApplication = this.predefinedIntegrations.find(
          (app) =>
            app._id ==
            macthedIntegrationField.integration_settings.selectedApplication
        );
        let events = macthedApplication.events;
        const matchedEvent = events.find(
          (event) =>
            event._id ===
            macthedIntegrationField.integration_settings.selectedEvent
        );
        if (matchedEvent.settings.eachRecordByEvent) {
          let variableEvent = events.find(
            (event) => event.asset === matchedEvent.settings.eachRecordByEvent
          );
          this.integrationVariables = variableEvent.settings.availableColumns;
        } else {
          this.integrationVariables = matchedEvent.settings.availableColumns;
        }
      } catch (err) {
        console.log("errrr", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-dialog {
  width: 100%;
}

.dialog-content {
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dialog-content::-webkit-scrollbar {
  width: 8px;
}

.dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: #888;
}

.dialog-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.event-type-options {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
</style>
